.TabBar {
  display: flex;
  justify-content: space-around;
}

.TabBar:hover {
  cursor: pointer;
}

.TabBarChild {
  flex: 1;
  padding: 8px;
  color: #a6a7aa;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
}

.TabBarChildActive {
  border-bottom: 4px solid #16805e;
  flex: 1;
  padding: 8px;
  color: #a6a7aa;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  /* letter-spacing: 0; this change is for test commit */
}

.overflow-auto {
  overflow: auto;
}